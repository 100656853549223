import { AppShell, Container, Stack } from "@mantine/core";
import Header from "./Header";
import Navbar from "./Navbar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { width as applicationWidth, deviceLayout } from "../../store";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom"
import { useViewportSize } from "@mantine/hooks";
import ReactGA from "react-ga4";

const Layout = () => {
  const { width } = useViewportSize();
  const layout = useRecoilValue(deviceLayout)
  const setWidth = useSetRecoilState(applicationWidth);
  useEffect(() => {
    setWidth(width);
  }, [setWidth, width]);

  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <AppShell
      navbar={<Navbar />}
      header={<Header />}
      navbarOffsetBreakpoint="sm"
      padding="md"
      fixed={true}
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0] }
      })}
    >
        <Container fluid sx={{ height: "100%" }} px={layout === "mobile" && 0}>
          <Stack spacing="xl" sx={{ height: "100%", position: "relative" }}>
            <Outlet />
          </Stack>
        </Container>
    </AppShell>
  );
};

export default Layout;
