import axios from "./axios";
import { PasswordChangeTicketResponse, User } from "@auth0/types";
import {
  ApisUserMetadata,
  CompanyT,
  CompanyUser,
  GuardRoleDefinitionT,
  NotificationSubscriptionT,
  UpdateNotificationSubscriptionBulkRequest,
  UpdateNotificationSubscriptionRequest,
  UpdateUserSelfRequest,
  UserSelfCompany, VerifyPhoneNumberRequest
} from "@apis/types";
import { DeviceNotificationEvent, UserNotificationChannel } from "@apis/queue";

export const updateProfileSelf = async (body: UpdateUserSelfRequest): Promise<User> => {
  const res = await axios.put<User>("user", body);
  return res.data;
}

export const updatePasswordSelf = async (): Promise<PasswordChangeTicketResponse> => {
  const res = await axios.put<PasswordChangeTicketResponse>("user/password");
  return res.data;
}

export const getMetadataSelf = async (): Promise<ApisUserMetadata> => {
  const res = await axios.get<ApisUserMetadata>("user/preferences");
  return res.data;
}

export const updateMetadataSelf = async (body: Partial<ApisUserMetadata>): Promise<ApisUserMetadata> => {
  const res = await axios.put<ApisUserMetadata>("user/preferences", body);
  return res.data;
}

export const getUserRoleDefinitions = async (): Promise<GuardRoleDefinitionT[]> => {
  const res = await axios.get<GuardRoleDefinitionT[]>("user/roles");
  return res.data;
}

export const getUserCompanies = async (): Promise<UserSelfCompany[]> => {
  const res = await axios.get<UserSelfCompany[]>("user/companies");
  return res.data;
}

export const acceptCompanyInvitation = async (companyID: string): Promise<CompanyUser> => {
  const res = await axios.post<CompanyUser>(`user/company/${companyID}/join`);
  return res.data;
}

export const leaveCompany = async (companyID: string): Promise<CompanyT> => {
  const res = await axios.delete<CompanyT>(`user/company/${companyID}/leave`);
  return res.data;
}

export const getUserNotificationSubscriptions = async (type: "landfill" | "device", resource: string): Promise<NotificationSubscriptionT[]> => {
  const res = await axios.get<NotificationSubscriptionT[]>(`user/subscriptions/${type}/${resource}`);
  return res.data;
}

export const updateUserNotificationSubscription = async (type: "landfill" | "device", resource: string, body: UpdateNotificationSubscriptionRequest): Promise<NotificationSubscriptionT> => {
  const res = await axios.post<NotificationSubscriptionT>(`user/subscriptions/${type}/${resource}`, body);
  return res.data;
}

export const getDeviceSubscriptionsOfLandfill = async (landfillID: string, event: DeviceNotificationEvent, channel: UserNotificationChannel): Promise<NotificationSubscriptionT[]> => {
  const res = await axios.get<NotificationSubscriptionT[]>(`user/subscriptions/landfill/${landfillID}/event/${event}/channel/${channel}`);
  return res.data;
}

export const updateDeviceSubscriptionsOfLandfill = async (landfillID: string, body: UpdateNotificationSubscriptionBulkRequest): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`user/subscriptions/landfill/${landfillID}/bulk`, body);
  return res.data;
}

export const startPhoneVerification = async (body: VerifyPhoneNumberRequest): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>("user/verify/mobile", body);
  return res.data;
}

export const completePhoneVerification = async (code: string): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`user/verify/mobile/${code}`);
  return res.data;
}
