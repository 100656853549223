export * from "./lib/device";
export * from "./lib/landfill";
export * from "./lib/asyncOperations";

export * from "./lib/alertParameters";
export * from "./lib/scheduler";
export * from "./lib/deviceStatuses";
export * from "./lib/controlAlgorithmSettings";
export * from "./lib/deviceCalibration";
export * from "./lib/metadata";
export * from "./lib/roles";
export * from "./lib/userActivity";
export * from "./lib/particle";
export * from "./lib/misc";
export * from "./lib/notifications"
export * from "./lib/verificationCode"
export * from "./lib/demoDevices"
export * from "./lib/chartComments"

export const FEATURE_VERSIONS = {
  "MONTHLY_DEVICE_AVERAGES": 4
}

export const PREFERRED_UNIT = {
  "SCFM": "SCFM",
  "SCF": "SCF",
  "CO2E": "Co2e",
  "MMBTU": "mmBTU",
  "RNG": "RNG"
} as const;

export const PREFERRED_UNITS = [
  PREFERRED_UNIT.SCFM,
  PREFERRED_UNIT.SCF,
  PREFERRED_UNIT.CO2E,
  PREFERRED_UNIT.MMBTU,
  PREFERRED_UNIT.RNG,
] as const;

export const PRESSURE_UNIT = {
  "WC": "WC",
  "HG": "HG",
  "HPA": "HPA",
} as const;

export const PRESSURE_UNITS = [
  PRESSURE_UNIT.HPA,
  PRESSURE_UNIT.HG,
  PRESSURE_UNIT.WC,
] as const;

export const TEMPERATURE_UNIT = {
  "F": "F",
  "C": "C",
} as const;

export const TEMPERATURE_UNITS = [
  TEMPERATURE_UNIT.F,
  TEMPERATURE_UNIT.C,
] as const;
