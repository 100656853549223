import { useMutation, useQuery, UseQueryOptions, UseMutationOptions, UseInfiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import * as DeviceRequests from "./service/device";
import {
  AlertHistory,
  CalibrationData,
  CalibrationLog,
  ControlAlgorithmTestRequest,
  CreateDeviceRequest,
  CurrentData,
  DeviceCharts,
  DeviceCurrentData,
  DeviceLogResponse,
  DeviceSample,
  DeviceStatusT,
  DeviceSummary,
  ApisDeviceT,
  PressureMonitorCharts,
  UpdateDeviceAlertParametersRequest,
  UpdateDeviceCalibrationSchedulerRequest,
  UpdateDeviceCanisterContentRequest,
  UpdateDeviceRequest,
  UpdateDeviceSchedulerRequest,
  UpdateDeviceStatusRequest,
  UpdateSampleVisibilityRequest,
  ValveHistory,
  ChartItem,
  ControlAlgorithmLog,
  WeatherStationCurrentData,
  WeatherStationCharts,
  AlertParameterT,
  SampleSummary,
  DeviceLog,
  ParticleCommandRequest,
  AsyncOperationReportType,
  CreatePreAutomationDataRequest,
  DevicePerformanceSummaryResponse
} from "@apis/types";
import { queryClient } from "./index";
import { DateTime } from "luxon";
import { AsyncOperationDeviceReport, DeviceLogType, PreAutomationData, PreAutomationDataSimple, DeviceSoC, PerformanceModel } from "@apis/dynamodb";

////////// QUERIES //////////
export const useGetLandfillDevices = (landfillID: string, options?: Omit<UseQueryOptions<ApisDeviceT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-devices", landfillID],
  queryFn: () => DeviceRequests.getDevicesOfLandfill(landfillID),
  ...options
});

export const useGetDevice = (deviceID: string, options?: Omit<UseQueryOptions<ApisDeviceT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device", deviceID],
  queryFn: () => DeviceRequests.getDevice(deviceID),
  ...options,
});

export const useGetDevicsByKeyword = (keyword: string, options?: Omit<UseQueryOptions<ApisDeviceT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-search", keyword],
  queryFn: () => DeviceRequests.getDevicesByKeyword(keyword),
  ...options,
});

export const useGetDeviceStatus = (deviceID: string, options?: Omit<UseQueryOptions<DeviceStatusT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-status", deviceID],
  queryFn: () => DeviceRequests.getDeviceStatus(deviceID),
  ...options
});

export const useGetDeviceVersion = (deviceID: string, options?: Omit<UseQueryOptions<{ version: number }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-version", deviceID],
  queryFn: () => DeviceRequests.getDeviceVersion(deviceID),
  ...options
});

export const useGetDeviceCurrentData = (deviceID: string, options?: Omit<UseQueryOptions<DeviceCurrentData>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-current-data", deviceID],
  queryFn: () => DeviceRequests.getDeviceCurrentData(deviceID),
  ...options
});

export const useGetDeviceAveragedData = (deviceID: string, options?: Omit<UseQueryOptions<CurrentData[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-averaged-data", deviceID],
  queryFn: () => DeviceRequests.getDeviceAveragedData(deviceID),
  ...options
});

export const useGetDevicePerformanceSummary = (deviceID: string, daily: boolean, startDate: string, endDate: string, options?: Omit<UseQueryOptions<DevicePerformanceSummaryResponse>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-performance", deviceID, startDate, endDate, daily ? "daily" : "monthly"],
  queryFn: () => daily ? DeviceRequests.getDeviceDailyPerformanceSummary(deviceID, startDate, endDate) : DeviceRequests.getDeviceMonthlyPerformanceSummary(deviceID, startDate, endDate),
  staleTime: 60 * 1000,
  ...options
});

export const useGetDevicePreAutomationData = (deviceID: string, options?: Omit<UseQueryOptions<PreAutomationDataSimple[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["pre-automation-data", deviceID],
  queryFn: () => DeviceRequests.getDevicePreAutomationData(deviceID),
  staleTime: 60 * 1000,
  ...options
});

export const useGetPerformanceModels = (deviceID: string, options?: Omit<UseQueryOptions<PerformanceModel[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["performance-models", deviceID],
  queryFn: () => DeviceRequests.getPerformanceModels(deviceID),
  staleTime: 60 * 1000,
  ...options
});

export const useGetWeatherStationCurrentData = (deviceID: string, options?: Omit<UseQueryOptions<WeatherStationCurrentData>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["weather-station-current-data", deviceID],
  queryFn: () => DeviceRequests.getWeatherStationCurrentData(deviceID),
  ...options
});

export const useGetDeviceCharts = <T extends DeviceCharts | PressureMonitorCharts | WeatherStationCharts>(deviceID: string, start: string, end: string, options?: Omit<UseQueryOptions<T>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-charts", deviceID, start, end],
  queryFn: () => DeviceRequests.getDeviceCharts(deviceID, start, end) as Promise<T>,
  ...options
});

export const useGetDeviceBatteryChart = (deviceID: string, startDate: string, endDate: string, options?: Omit<UseQueryOptions<ChartItem[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-battery-chart", deviceID],
  queryFn: () => DeviceRequests.getDeviceBatteryChart(deviceID, startDate, endDate),
  ...options
});


export const useGetDeviceBatteryLevel = (deviceID: string, options?: Omit<UseQueryOptions<DeviceSoC | undefined>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-battery-level", deviceID],
  queryFn: () => DeviceRequests.getDeviceBatteryLevel(deviceID),
  staleTime: 30 * 1000,
  ...options
});

export const useGetDeviceSummary = (deviceID: string, options?: Omit<UseQueryOptions<DeviceSummary>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-summary", deviceID],
  queryFn: () => DeviceRequests.getDeviceSummary(deviceID),
  ...options
});

export const useGetDeviceServiceView = (deviceID: string, options?: Omit<UseQueryOptions<{ calibration?: SampleSummary, leakTest?: SampleSummary }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-service-view", deviceID],
  queryFn: () => DeviceRequests.getDeviceServiceView(deviceID),
  ...options
});

export const useGetDeviceAlertHistory = (deviceID: string, options?: Omit<UseQueryOptions<AlertHistory[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-alert-history", deviceID],
  queryFn: () => DeviceRequests.getDeviceAlertHistory(deviceID),
  ...options
});

export const useGetDeviceCalibrationData = (deviceID: string, options?: Omit<UseQueryOptions<{ before?: CalibrationData, after: CalibrationData, status: boolean }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-calibration-data", deviceID],
  queryFn: () => DeviceRequests.getDeviceCalibrationData(deviceID),
  ...options
});

export const useGetDeviceZeroCalibrationData = (deviceID: string, options?: Omit<UseQueryOptions<{ before?: CalibrationData, after: CalibrationData, status: boolean }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-zero-calibration-data", deviceID],
  queryFn: () => DeviceRequests.getDeviceZeroCalibrationData(deviceID),
  ...options
});

export const useGetDeviceCalibrationLog = (deviceID: string, options?: Omit<UseQueryOptions<{ prev: CalibrationLog, curr: CalibrationLog, factory: CalibrationLog }>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-calibration-log", deviceID],
  queryFn: () => DeviceRequests.getDeviceCalibrationLog(deviceID),
  ...options
});

export const useGetValveAdjustmentHistory = (deviceID: string, options?: Omit<UseInfiniteQueryOptions<ValveHistory[], Error, ValveHistory[], ValveHistory[], string[], string>, "queryKey" | "queryFn">) => useInfiniteQuery({
  queryKey: ["valve-adjustment-history", deviceID],
  queryFn: ({ pageParam }) => DeviceRequests.getValveAdjustmentHistory(deviceID, pageParam),
  select: (data) => data.pages.flat(),
  getNextPageParam: (lastPage) => lastPage?.length ? lastPage[lastPage.length - 1].date : null,
  ...options
});

export const useGetDeviceHistoricalData = (deviceID: string, date?: string, options?: Omit<UseQueryOptions<DeviceSample[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-historical-data", deviceID, date],
  queryFn: () => DeviceRequests.getDeviceHistoricalData(deviceID, date),
  ...options
});

export const useGetDeviceLogsRecursively = (deviceID: string, options?: Omit<UseInfiniteQueryOptions<DeviceLogResponse, Error, DeviceLog[], DeviceLogResponse, string[], string>, "queryKey" | "queryFn">) => useInfiniteQuery({
  queryKey: ["device-logs-recursively", deviceID],
  queryFn: (item) => {
    const { pageParam, direction } = item
    if (direction === "backward") {
      if (!pageParam) {
        return DeviceRequests.getDeviceLogsRecursively(deviceID)
      } else {
        return DeviceRequests.getDeviceLogsRecursively(deviceID, DateTime.fromISO(pageParam, { zone: "utc" }).plus({ seconds: 1 }).toISO(), DateTime.utc().toISO())
      }
    } else {
      return DeviceRequests.getDeviceLogsRecursively(deviceID, pageParam)
    }
  },
  select: (data) => data.pages.map(v => v.logs.filter(log => v.logs.length > 1 ? true : log.date !== data.pageParams?.[0])).flat(),
  getNextPageParam: (logs) => {
    return logs.lastKey
  },
  getPreviousPageParam: (logs, _, lastPageParam, __) => {
    if (!logs.firstKey && !logs.lastKey) return lastPageParam
    return logs.firstKey
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 600 * 1000, //Prevent refetch
  ...options
});

export const useGetDeviceLogsByType = (deviceID: string, startDate: string, endDate: string, types?: DeviceLogType[], options?: Omit<UseInfiniteQueryOptions<DeviceLogResponse, Error, DeviceLog[], DeviceLogResponse, string[], string>, "queryKey" | "queryFn">) => useInfiniteQuery({
  queryKey: ["device-logs-type", deviceID, startDate, endDate, types?.join()],
  queryFn: (item) => {
    const { pageParam } = item
    if (!pageParam) {
      const start = DateTime.fromISO(endDate, { zone: "utc" }).minus({ hours: 24 }).toISO()
      return DeviceRequests.getDeviceLogs(deviceID, start >= startDate ? start : startDate, endDate, types)
    } else {
      if (pageParam === startDate) return null
      const start = DateTime.fromISO(pageParam, { zone: "utc" }).minus({ hours: 24 }).toISO()
      return DeviceRequests.getDeviceLogs(deviceID, start >= startDate ? start : startDate, pageParam, types)
    }
  },
  select: (data) => data.pages.map(v => !v ? [] : v.logs.filter(log => log.date !== data.pageParams?.[0] && log.date !== data.pageParams?.[1])).flat(),
  getNextPageParam: (lastPage, _, lastPageParam) => {
    if (lastPageParam !== undefined && lastPageParam === startDate) {
      return undefined
    }
    return lastPage?.lastKey
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 60 * 1000,
  ...options
});

export const useGetDeviceReports = (deviceID: string, type: AsyncOperationReportType, startDate: string, endDate: string, options?: Omit<UseQueryOptions<AsyncOperationDeviceReport[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["device-reports", deviceID],
  queryFn: () => DeviceRequests.getDeviceReports(deviceID, type, startDate, endDate),
  ...options
});

////////// MUTATIONS //////////
export const useCreateDevice = (options?: UseMutationOptions<ApisDeviceT, Error, CreateDeviceRequest>) => useMutation({
  mutationKey: ["create-device"],
  mutationFn: (body) => DeviceRequests.createDevice(body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-devices", args[0].landfill], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateDevice = (deviceID: string, options?: UseMutationOptions<ApisDeviceT, Error, UpdateDeviceRequest>) => useMutation({
  mutationKey: ["update-device", deviceID],
  mutationFn: (body) => DeviceRequests.updateDevice(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-devices", args[0].landfill], exact: true })
    queryClient.refetchQueries({ queryKey: ["device", args[0].id], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteDevice = (deviceID: string, options?: UseMutationOptions<ApisDeviceT>) => useMutation({
  mutationKey: ["delete-device", deviceID],
  mutationFn: () => DeviceRequests.deleteDevice(deviceID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-devices", args[0].landfill],exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateDeviceStatus = (deviceID: string, options?: UseMutationOptions<DeviceStatusT, Error, UpdateDeviceStatusRequest>) => useMutation({
  mutationKey: ["update-device-status", deviceID],
  mutationFn: (body) => DeviceRequests.updateDeviceStatus(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-status", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useGetDeviceStatusHistory = (deviceID: string, options?: UseMutationOptions<DeviceStatusT[]>) => useMutation({
  mutationKey: ["device-status-history", deviceID],
  mutationFn: () => DeviceRequests.getDeviceStatusHistory(deviceID),
  ...options
});

export const useUpdateDeviceScheduler = (deviceID: string, landfillID: string, options?: UseMutationOptions<ApisDeviceT, Error, UpdateDeviceSchedulerRequest>) => useMutation({
  mutationKey: ["update-device-scheduler", deviceID],
  mutationFn: (body) => DeviceRequests.updateDeviceScheduler(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device", args[0].id], exact: true })
    queryClient.refetchQueries({ queryKey: ["landfill-devices", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAdjustValvePosition = (deviceID: string, options?: UseMutationOptions<{ message: string, status: boolean }, Error, number>) => useMutation({
  mutationKey: ["adjust-valve-position", deviceID],
  mutationFn: (value) => DeviceRequests.adjustValvePosition(deviceID, value),
  ...options
});

export const useTakeDeviceSample = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["take-device-sample", deviceID],
  mutationFn: () => DeviceRequests.takeDeviceSample(deviceID),
  ...options
});

export const useTakeDeviceLeakTest = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["take-leak-test", deviceID],
  mutationFn: () => DeviceRequests.takeDeviceLeakTest(deviceID),
  ...options
});

export const useUpdateDeviceAlertParameter = (deviceID: string, options?: UseMutationOptions<AlertParameterT, Error, UpdateDeviceAlertParametersRequest>) => useMutation({
  mutationKey: ["update-device-alert-parameter", deviceID],
  mutationFn: (body) => DeviceRequests.updateDeviceAlertParameter(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-alert-parameters", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDownloadDeviceSamples = (deviceID: string, start: string, end: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["download-device-samples", deviceID, start, end],
  mutationFn: () => DeviceRequests.downloadDeviceSamples(deviceID, start, end),
  ...options
});

export const useUpdateDeviceApisID = (deviceID: string, options?: UseMutationOptions<ApisDeviceT, Error, string>) => useMutation({
  mutationKey: ["update-device-apis-id", deviceID],
  mutationFn: (apisID) => DeviceRequests.updateDeviceApisID(deviceID, apisID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDownloadAlertHistory = (deviceID: string, start: string, end: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["download-alert-history", deviceID, start, end],
  mutationFn: () => DeviceRequests.downloadAlertHistory(deviceID, start, end),
  ...options
});

export const useTestControlAlgorithm = (deviceID: string, options?: UseMutationOptions<ControlAlgorithmLog, Error, ControlAlgorithmTestRequest>) => useMutation({
  mutationKey: ["test-control-algorithm", deviceID],
  mutationFn: (body) => DeviceRequests.testControlAlgorithm(body),
  ...options
});

export const useRunDeviceCommand = (deviceID: string, options?: UseMutationOptions<{ message: string }, Error, ParticleCommandRequest>) => useMutation({
  mutationKey: ["run-device-command", deviceID],
  mutationFn: (body) => DeviceRequests.runDeviceCommand(deviceID, body),
  ...options
});

export const useStartDeviceCalibration = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["start-device-calibration", deviceID],
  mutationFn: () => DeviceRequests.startDeviceCalibration(deviceID),
  ...options
});

export const useStartDeviceZeroCalibration = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["start-zero-calibration", deviceID],
  mutationFn: () => DeviceRequests.startDeviceZeroCalibration(deviceID),
  ...options
});

export const useRestoreDeviceCalibration = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["restore-device-calibration", deviceID],
  mutationFn: () => DeviceRequests.restoreDeviceCalibration(deviceID),
  ...options
});

export const useSetDeviceCanisterContent = (deviceID: string, options?: UseMutationOptions<ApisDeviceT, Error, UpdateDeviceCanisterContentRequest>) => useMutation({
  mutationKey: ["set-device-canister-content", deviceID],
  mutationFn: (body) => DeviceRequests.setDeviceCanisterContent(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device", deviceID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useSetDeviceCalibrationScheduler = (deviceID: string, landfillID: string, options?: UseMutationOptions<ApisDeviceT, Error, UpdateDeviceCalibrationSchedulerRequest>) => useMutation({
  mutationKey: ["set-device-calibration-scheduler", deviceID],
  mutationFn: (body) => DeviceRequests.setDeviceCalibrationScheduler(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device", deviceID], exact: true })
    queryClient.refetchQueries({ queryKey: ["landfill-devices", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
}
);

export const useUpdateSampleVisibility = (deviceID: string, date: string, options?: UseMutationOptions<DeviceSample, Error, UpdateSampleVisibilityRequest>) => useMutation({
  mutationKey: ["update-sample-visibility", deviceID, date],
  mutationFn: (body) => DeviceRequests.updateSampleVisibility(deviceID, date, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["device-historical-data", deviceID, DateTime.fromISO(date, { zone: "utc" }).toISODate()], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useCreatePreAutomationData = (deviceID: string, options?: UseMutationOptions<PreAutomationData, Error, CreatePreAutomationDataRequest>) => useMutation({
  mutationKey: ["create-pre-automation-data", deviceID],
  mutationFn: (body) => DeviceRequests.createPreAutomationData(deviceID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["pre-automation-data", deviceID], exact: true })
    const variables = args[1]
    if (variables.regenerateModel) {
      setTimeout(() => {
        queryClient.refetchQueries({ queryKey: ["performance-models", deviceID], exact: true })
        queryClient.refetchQueries({ queryKey: ["device-performance", deviceID], exact: false })
      }, 5000)
    }
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeletePreAutomationData = (deviceID: string, options?: UseMutationOptions<{ message: string }, Error, { date: string, regenerateModel: boolean }>) => useMutation({
  mutationKey: ["create-pre-automation-data", deviceID],
  mutationFn: ({ date, regenerateModel }) => DeviceRequests.deletePreAutomationData(deviceID, date, regenerateModel),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["pre-automation-data", deviceID], exact: true })
    const variables = args[1]
    if (variables.regenerateModel) {
      setTimeout(() => {
        queryClient.refetchQueries({ queryKey: ["performance-models", deviceID], exact: true })
        queryClient.refetchQueries({ queryKey: ["device-performance", deviceID], exact: false })
      }, 5000)
    }
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useGeneratePerformanceModel = (deviceID: string, options?: UseMutationOptions<{ message: string }>) => useMutation({
  mutationKey: ["generate-performance-model", deviceID],
  mutationFn: () => DeviceRequests.generatePerformanceModel(deviceID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["performance-models", deviceID], exact: true })
    setTimeout(() => {
      queryClient.refetchQueries({ queryKey: ["device-performance", deviceID], exact: false })
    }, 5000)
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeletePerformanceModel = (deviceID: string, options?: UseMutationOptions<{ message: string }, Error, string>) => useMutation({
  mutationKey: ["delete-performance-model", deviceID],
  mutationFn: (modelDate) => DeviceRequests.deletePerformanceModel(deviceID, modelDate),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["performance-models", deviceID], exact: true })
    queryClient.refetchQueries({ queryKey: ["device-performance", deviceID], exact: false })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useGetDeviceReport = (deviceID: string, options?: UseMutationOptions<{ message: string } | { url: string, name: string }, Error, { reportDate: string, reportType: AsyncOperationReportType }>) => useMutation({
  mutationKey: ["device-report-by-type", deviceID],
  mutationFn: (body) => DeviceRequests.getDeviceReport(deviceID, body.reportType, body.reportDate),
  ...options
});
