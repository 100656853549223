import axios from "./axios";
import { ChartCommentT, ChartCommentType, CreateChartCommentRequest, UpdateChartCommentRequest } from "@apis/types";

export const createChartComment = async (resourceType: "device" | "landfill", identifier: string, body: CreateChartCommentRequest): Promise<ChartCommentT> => {
  const res = await axios.post<ChartCommentT>(`chart-comments/${resourceType}/${identifier}`, body);
  return res.data;
}

export const getChartComments = async (resourceType: "device" | "landfill", identifier: string, commentType: ChartCommentType, startDate: string, endDate: string): Promise<ChartCommentT[]> => {
  const res = await axios.get<ChartCommentT[]>(`chart-comments/${resourceType}/${identifier}/${commentType}`, { params: { startDate, endDate } });
  return res.data;
}

export const updateChartComment = async (commentID: string, body: UpdateChartCommentRequest): Promise<ChartCommentT> => {
  const res = await axios.put<ChartCommentT>(`chart-comments/${commentID}`, body);
  return res.data;
}

export const deleteChartComment = async (commentID: string): Promise<ChartCommentT> => {
  const res = await axios.delete<ChartCommentT>(`chart-comments/${commentID}`);
  return res.data;
}
