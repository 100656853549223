import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as ChartCommentsRequests from "./service/chartComments";
import { ChartCommentType, ChartCommentT, UpdateChartCommentRequest, CreateChartCommentRequest } from "@apis/types";
import { queryClient } from "./index";

////////// QUERIES //////////
export const useGetChartComments = (resourceType: "device" | "landfill", identifier: string, commentType: ChartCommentType, startDate: string, endDate: string, options?: Omit<UseQueryOptions<ChartCommentT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["chart-comments", resourceType, identifier, commentType, startDate, endDate],
  queryFn: () => ChartCommentsRequests.getChartComments(resourceType, identifier, commentType, startDate, endDate),
  ...options
});


////////// MUTATIONS //////////
export const useCreateChartComment = (resourceType: "device" | "landfill", identifier: string, options?: UseMutationOptions<ChartCommentT, Error, CreateChartCommentRequest>) => useMutation({
  mutationKey: ["create-chart-comment", resourceType, identifier],
  mutationFn: (body) => ChartCommentsRequests.createChartComment(resourceType, identifier, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.invalidateQueries({ queryKey: ["chart-comments"] })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
export const useUpdateChartComment = (commentID: string, options?: UseMutationOptions<ChartCommentT, Error, UpdateChartCommentRequest>) => useMutation({
  mutationKey: ["update-chart-comment", commentID],
  mutationFn: (body) => ChartCommentsRequests.updateChartComment(commentID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.invalidateQueries({ queryKey: ["chart-comments"] })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteChartComment = (commentID: string, options?: UseMutationOptions<ChartCommentT>) => useMutation({
  mutationKey: ["delete-chart-comment", commentID],
  mutationFn: () => ChartCommentsRequests.deleteChartComment(commentID),
  ...options,
  onSuccess: (...args) => {
    queryClient.invalidateQueries({ queryKey: ["chart-comments"] })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
