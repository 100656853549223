import { useAuth0 } from "@auth0/auth0-react";
import { ApisUserMetadata, UserRole } from "@apis/types";
import { useLocalStorage } from "@mantine/hooks";
import { DEFAULT_USER_METADATA } from "@apis/constants";
import { useGetMetadataSelf, useUpdateMetadataSelf } from "../query";
import { useEffect } from "react";
import { setUser } from "@sentry/react";
import ReactGA from "react-ga4";
import { environment } from "../environments/environment";

export const useUserMetadata = (): { preferences: ApisUserMetadata, isUpdating: boolean, updatePreferences: (preferences: Partial<ApisUserMetadata>) => Promise<void>, role: UserRole } => {
  const { user } = useAuth0();

  const { data: metadataSelf, isSuccess } = useGetMetadataSelf({
    enabled: !!user,
    refetchOnMount: false,
  })

  useEffect(() => {
    if (!user) {
      setUser(null)
      ReactGA.set({ user_id: undefined,  user_properties: { app_version: environment.APP_VERSION } });
    } else {
      setUser({
        id: user.sub,
        username: user.name,
        email: user.email
      })
      ReactGA.set({ user_id: user.sub,  user_properties: { app_version: environment.APP_VERSION } });
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      setPreferences(metadataSelf)
    }
  }, [metadataSelf, isSuccess]);

  const [preferences, setPreferences] = useLocalStorage<ApisUserMetadata>({
    key: "preferences",
    defaultValue: {
      ...DEFAULT_USER_METADATA,
      ...(user?.["user_preferences"] || {}),
    },
  });

  const { mutateAsync, isPending: isLoading } = useUpdateMetadataSelf({ onSuccess: setPreferences })

  return {
    preferences: preferences,
    updatePreferences: async (U) => {
      setPreferences(prev => ({ ...prev, ...U }));
      await mutateAsync(U)
    },
    isUpdating: isLoading,
    role: user?.["user_role"] || "company-user"
  };
};
