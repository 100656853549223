export const PARTICLE_COMMAND = {
  OTHER: "other",
  SAMPLE: "SAMPLE",
  VALVE_ADJUSTMENT: "POS",
  CALIBRATION: "C8",
  ZERO_CALIBRATION: "C7",
  RESTORE_PREVIOUS_CALIBRATION: "restorePreviousCalibration",
  SET_CANISTER_CONTENT: "setCalibrationCanisterContent",
  SET_CLOUD_CONNECTOR_LINK: "setCloudConnectorLink",
  SET_VACUUM: "setVac"
} as const;

export const PARTICLE_COMMANDS = [
  PARTICLE_COMMAND.OTHER,
  PARTICLE_COMMAND.SAMPLE,
  PARTICLE_COMMAND.VALVE_ADJUSTMENT,
  PARTICLE_COMMAND.CALIBRATION,
  PARTICLE_COMMAND.ZERO_CALIBRATION,
  PARTICLE_COMMAND.RESTORE_PREVIOUS_CALIBRATION,
  PARTICLE_COMMAND.SET_CANISTER_CONTENT,
  PARTICLE_COMMAND.SET_CLOUD_CONNECTOR_LINK,
  PARTICLE_COMMAND.SET_VACUUM
] as const
