import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Chart, ChartOptions, ChartType, LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, Filler } from "chart.js";
import { CustomChartOptions, Dataset } from "../components/Chart/types";
import "chartjs-adapter-luxon"
import CustomLineChart from "../components/Chart/CustomLineChart";
import ChartCommentPlugin from "../components/Chart/ChartCommentPlugin";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import { useMantineTheme } from "@mantine/core";
import { DateTime } from "luxon";

Chart.register(
  zoomPlugin,
  ChartCommentPlugin,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  CustomLineChart,
  Filler,
  annotationPlugin
);

const lineChartType: ChartType = "customLineChart";

export const useChart = (ref: MutableRefObject<HTMLCanvasElement | null>, data: Dataset[], options?: CustomChartOptions) => {
  const theme = useMantineTheme();
  const [chart, setChart] = useState<Chart<"customLineChart"> | null>(null);
  const zoomRef = useRef<[string, string] | null>(null);
  const resetZoom = () => {
    chart?.resetZoom();
    zoomRef.current = null
  }

  const doesHaveSecondYAxis = useMemo(() => data.some(d => d.yAxisID === "y2"), [data]);
  const dynamicOptions = useMemo<ChartOptions<"customLineChart">>(() => ({
    spanGaps: true,
    responsive: true,
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.4
      },
      point: {
        radius: 0
      }
    },
    animation: false,
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    plugins: {
      chartCommentPlugin: {
        data: options?.comments?.data || [],
        onClick: options?.comments?.onClick
      },
      legend: {
        display: true,
        labels: {
          generateLabels: (chart: Chart) => {
            const datasets = chart.data.datasets as Dataset[];
            return datasets.map((dataset, index) => {
              const value = dataset.data[dataset.data.length - 1].y;
              return {
                text: `${dataset.label}: ${value} ${dataset.unit || ""}`,
                fillStyle: dataset.borderColor,
                strokeStyle: dataset.borderColor,
                fontColor: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[6],
                hidden: !chart.isDatasetVisible(index),
                datasetIndex: index,
              }
            });
          }
        }
      },
      tooltip: {
        position: "nearest",
        displayColors: false,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderWidth: 1,
        borderColor: "black",
        bodyFont: {
          weight: "bold",
          size: 13
        },
        titleFont: {
          weight: "bold",
          size: 15
        },
        callbacks: {
          label: (context) => {
            // @ts-ignore TODO Investigate types in custom chart types
            return `${context.dataset.label}: ${context.parsed.y} ${context.dataset.unit}`;
          },
          // @ts-ignore
          title: (context) => options?.tooltip?.titleFormatter ? options.tooltip.titleFormatter(context) : DateTime.fromISO(context[0]?.raw.x, { setZone: true }).toFormat("f ZZZZ"),
          afterBody: (context) => options?.tooltip?.afterBody && options.tooltip.afterBody(context)
        }
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: "x",
          onZoomComplete: ({ chart }) => {
            const xScale = chart.scales["x"];
            if (xScale) {
              zoomRef.current = [
                DateTime.fromMillis(xScale.min, { zone: "utc" }).toISO(),
                DateTime.fromMillis(xScale.max, { zone: "utc" }).toISO(),
              ]
            }
          }
        },
        limits: {
          x: {
            min: "original",
            max: "original",
          }
        }
      },
      annotation: {
        annotations: {
          line: {
            display: !!options?.verticalLine?.verticalLineFormatter()?.xAxisLine,
            type: "line",
            xMin: () => options?.verticalLine?.verticalLineFormatter && options?.verticalLine.verticalLineFormatter()?.xAxisLine,
            xMax: () => options?.verticalLine?.verticalLineFormatter && options?.verticalLine.verticalLineFormatter()?.xAxisLine,
            yMin: "original",
            yMax: "original",
            borderColor: () => options?.verticalLine?.verticalLineFormatter && options?.verticalLine.verticalLineFormatter()?.borderColor,
            borderWidth: 1,
          },
          ...(options.annotations || []).reduce((acc, annotation, i) => {
            acc[`annotation-${i}`] = annotation
            return acc;
          }, {})
        }
      }
    },
    scales: {
      x: { type: "time", time: { unit: options?.time?.unit || "day", isoWeekday: true, round: options?.time?.round || false } },
      y: { type: "linear", display: true, position: "left", ...(options?.scales?.y || {}) },
      y2: { type: "linear", display: doesHaveSecondYAxis, position: "right", id: "y2", ...(options?.scales?.y2 || {}) }
    }
  }), [doesHaveSecondYAxis, options]);

  useEffect(() => {
    const c: Chart<"customLineChart"> | null = ref.current && new Chart(ref.current, {
      type: lineChartType,
      data: { datasets: data },
      options: dynamicOptions
    });
    setChart(c);
    return () => {
      chart?.destroy()
      c?.destroy();
    };
  }, []);

  useEffect(() => {
    if(chart) {
      chart.data.datasets = data;
      chart.update();
    }
  }, [chart, data]);

  useEffect(() => {
    if(chart) {
      chart.options = dynamicOptions;
      chart.update();
    }
  }, [chart, dynamicOptions])

  useEffect(() => {
    if(chart?.options.plugins?.legend?.labels) {
      chart.options.plugins.legend.labels.generateLabels = (chart: Chart) => {
        const datasets = chart.data.datasets as Dataset[];
        return datasets.map((dataset, index) => {
          const value = dataset.data[dataset.data.length - 1].y;
          return {
            text: `${dataset.label}: ${value} ${dataset.unit || ""}`,
            fillStyle: dataset.borderColor,
            strokeStyle: dataset.strokeStyle || dataset.borderColor,
            fontColor: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[6],
            hidden: !chart.isDatasetVisible(index),
            segment: dataset.segment,
            datasetIndex: index,
          }
        });
      }
      chart.update()
    }
  }, [chart, theme]);

  return { chart, zoomRef, resetZoom };
};
