import { MutableRefObject, useEffect, useMemo, useState } from "react"
import { Chart, BarController, BarElement, CategoryScale, Tooltip, ChartOptions, ChartType } from "chart.js"
import { HorizontalBarDataset } from "../components/HorizontalBarChart/types"
import { BarChartItem } from "@apis/types"
import { useNavigate } from "react-router-dom"

Chart.register(BarController, BarElement, CategoryScale, Tooltip)

const barChartType: ChartType = "bar"

export const useBarChart = (
  ref: MutableRefObject<HTMLCanvasElement | null>,
  data: HorizontalBarDataset[],
  options?: ChartOptions<"bar">
) => {
  const navigate = useNavigate()
  const [chart, setChart] = useState<Chart<"bar", BarChartItem[]> | null>(null)

  const dynamicOptions = useMemo<ChartOptions<"bar">>(() => ({
    responsive: true,
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.formattedValue}`
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        position: "top",
      },
      y: {
        grid: { display: false },
        ticks: {
          font: { size: 12 },
        },
      },
    },
    ...options,
  }), [options])

  useEffect(() => {
    const c: Chart<"bar", BarChartItem[]> | null = ref.current && new Chart(ref.current, {
      type: barChartType,
      data: { datasets: data },
      options: dynamicOptions,
    })
    setChart(c)

    return () => {
      chart?.destroy()
      c.destroy()
    }
  }, [])

  useEffect(() => {
    if (chart) {
      chart.data.datasets = data
      chart.update()
    }
  }, [chart, data])

  useEffect(() => {
    if (chart) {
      chart.options = dynamicOptions
      chart.update()
    }
  }, [chart, dynamicOptions])

  useEffect(() => {
    const handleLabelClick = (event) => {
      const { canvas, scales: { y } } = chart
      let rect = canvas.getBoundingClientRect()

      const { top, left, right, ticks } = y
      const height = y.height / ticks.length

      const xCoor = event.clientX - rect.left
      const yCoor = event.clientY - rect.top

      for (let i = 0; i < y.ticks.length; i++) {
        if (xCoor >= left && xCoor <= right && yCoor >= top + (height * i) && yCoor <= top + height + (height * i)) {
          const deviceId = chart.data.datasets[0].data[i].id;
          if (deviceId) {
            canvas.style.cursor = "pointer"
            if (event.type === "click") navigate(`/device-performance/${chart.data.datasets[0].data[i].id}${window.location.search}`)
            return
          }
        }
      }
      canvas.style.cursor = "default"
    }

    chart?.canvas.addEventListener("mousemove", handleLabelClick);
    chart?.canvas.addEventListener("click", handleLabelClick);

    return () => {
      chart?.canvas?.removeEventListener("mousemove", handleLabelClick);
      chart?.canvas?.removeEventListener("click", handleLabelClick);
    };
  }, [chart?.canvas, options]);

  return { chart }
}
