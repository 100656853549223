import { MutableRefObject, useEffect, useMemo, useState } from "react"
import { Chart, DoughnutController, ArcElement, Tooltip, ChartOptions, ChartType, LegendItem, ChartData } from "chart.js"
import { DoughnutChartDataset } from "../components/Chart/types";

Chart.register(DoughnutController, ArcElement, Tooltip)

const doughnutChartType: ChartType = "doughnut"

export const useDoughnutChart = (ref: MutableRefObject<HTMLCanvasElement | null>, data: DoughnutChartDataset, options?: ChartOptions<typeof doughnutChartType>) => {
  const [chart, setChart] = useState<Chart<typeof doughnutChartType, number[]> | null>(null)

  const dynamicOptions = useMemo<ChartOptions<typeof doughnutChartType>>(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          padding: 5,
          fontSize: 10,
          boxWidth: 20,
          textAlign: "left",
          sort(a: LegendItem, b: LegendItem, data: ChartData<typeof doughnutChartType, number[]>): number {
            const values = data.datasets[0].data
            const [A, B]: [number, number] = [values[a.index], values[b.index]]
            if (A > B) return -1
            if (A < B) return 1
            return 0
          }
        }
      },
      tooltip: {
        mode: "point",
        intersect: true,
        displayColors: true,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderWidth: 1,
        borderColor: "black",
        bodyFont: {
          weight: "bold",
          size: 10
        },
        titleFont: {
          weight: "bold",
          size: 12
        },
        callbacks: {
          title: (context) => data.keys[context[0].dataIndex]
        }
      },
    },
    ...options,
  }), [data.keys, options])

  useEffect(() => {
    const c: Chart<typeof doughnutChartType, number[], unknown> = ref.current && new Chart(ref.current, {
      type: doughnutChartType,
      data: { datasets: [data], labels: data.labels },
      options: dynamicOptions,
    })
    setChart(c)

    return () => {
      chart?.destroy()
      c.destroy()
    }
  }, [])

  useEffect(() => {
    if (chart) {
      chart.data.datasets = [data]
      chart.update()
    }
  }, [chart, data])

  useEffect(() => {
    if (chart) {
      chart.options = dynamicOptions
      chart.update()
    }
  }, [chart, dynamicOptions])

  return { chart }
}
