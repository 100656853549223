import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Login from "./Login";
import { Suspense, lazy } from "react";
import { faHome, faChartSimple, faDumpster, faBuilding, faMapLocationDot, faClockRotateLeft, faToolbox, faGaugeHigh, faFileContract } from "@fortawesome/free-solid-svg-icons";
import ProtectedRoute from "./ProtectedRoute";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { UserScope } from "@apis/types";
import { LoadingOverlay } from "@mantine/core";
import ErrorPage from "./ErrorPage";
import { wrapCreateBrowserRouter } from "@sentry/react";

const Dashboard = lazy(() => import("./Dashboard"));
const Dataview = lazy(() => import("./Dataview"));
const Landfill = lazy(() => import("./Landfill"));
const CompanyPerformance = lazy(() => import("./CompanyPerformance"));
const LandfillPerformance = lazy(() => import("./LandfillPerformance"));
const DevicePerformance = lazy(() => import("./DevicePerformance"));
const ManageLandfills = lazy(() => import("./ManageLandfills"));
const ManageLandfill = lazy(() => import("./ManageLandfill"));
const ManageDevice = lazy(() => import("./ManageDevice"));
const ManageCompanies = lazy(() => import("./ManageCompanies"));
const CompaniesSelf = lazy(() => import("./CompaniesSelf"));
const ManageCompany = lazy(() => import("./ManageCompany"));
const Account = lazy(() => import("./Account"));
const GeoviewMain = lazy(() => import("./GeoviewMain"));
const GeovievLandfill = lazy(() => import("./GeovievLandfill"));
const DeviceLogs = lazy(() => import("./DeviceLogs"));
const DataviewDeviceGroup = lazy(() => import("./DataviewDeviceGroup"));
const ManageDeviceGroup = lazy(() => import("./ManageDeviceGroup"));
const Admin = lazy(() => import("./Admin"))
const Reports = lazy(() => import("./Reports"));

type MenuRoute = {
  label: string,
  menu: true,
  icon: IconDefinition,
  to: string,
}

type PathRoute = {
  menu: false,
}

type RouteType = (MenuRoute | PathRoute) & {
  to: string,
  element: JSX.Element,
  scope?: UserScope,
  resources?: { companyKey?: string, landfillKey?: string }
  validation?: { params?: Record<string, (arg: string) => boolean>, fallbackURL?: string }
}

export const routes: RouteType[] = [
  {
    label: "DASHBOARD",
    to: "/",
    element: <Dashboard />,
    icon: faHome,
    menu: true
  },
  {
    label: "DATAVIEW",
    to: "/data/",
    element: <Dataview />,
    icon: faChartSimple,
    menu: true
  },
  {
    to: "/data/:DEVICE_ID",
    element: <Dataview />,
    menu: false,
    validation: {
      params: { "DEVICE_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/data/"
    }
  },
  {
    to: "/data-dg/:GROUP_ID",
    element: <DataviewDeviceGroup />,
    menu: false,
    validation: {
      params: { "GROUP_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/data/"
    }
  },
  {
    label: "GEOVIEW",
    to: "/geo/",
    element: <GeoviewMain />,
    icon: faMapLocationDot,
    menu: true
  },
  {
    to: "/geo/:LANDFILL_ID",
    element: <GeovievLandfill />,
    menu: false,
    validation: {
      params: { "LANDFILL_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/geo/"
    }
  },
  {
    to: "/account",
    element: <Account />,
    menu: false
  },
  {
    to: "/companies",
    element: <CompaniesSelf />,
    menu: false
  },
  {
    to: "/landfill/:LANDFILL_ID",
    element: <Landfill />,
    menu: false,
    validation: {
      params: { "LANDFILL_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/"
    }
  },
  {
    label: "REPORTS",
    to: "/landfill-reports/",
    element: <Reports />,
    icon: faFileContract,
    menu: true,
    scope: "read:landfill"
  },
  {
    to: "/landfill-reports/:LANDFILL_ID",
    element: <Reports />,
    menu: false,
    validation: {
      params: { "LANDFILL_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/landfill-reports/"
    },
    scope: "read:landfill",
    resources: { landfillKey: "LANDFILL_ID" }
  },
  {
    to: "/device-reports/:DEVICE_ID",
    element: <Reports />,
    menu: false,
    scope: "read:device",
    validation: {
      params: { "DEVICE_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/landfill-reports/"
    }
  },
  {
    label: "PERFORMANCE",
    to: "/company-performance/",
    element: <CompanyPerformance />,
    scope: "read:performance-dashboard",
    icon: faGaugeHigh,
    menu: true,
  },
  {
    to: "/company-performance/:COMPANY_ID",
    element: <CompanyPerformance />,
    resources: { companyKey: "COMPANY_ID" },
    scope: "read:performance-dashboard",
    menu: false,
    validation: {
      params: { "COMPANY_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/company-performance/"
    }
  },
  {
    to: "/landfill-performance/",
    element: <LandfillPerformance />,
    scope: "read:performance-dashboard",
    menu: false
  },
  {
    to: "/landfill-performance/:LANDFILL_ID",
    element: <LandfillPerformance />,
    resources: { landfillKey: "LANDFILL_ID" },
    scope: "read:performance-dashboard",
    menu: false,
    validation: {
      params: { "LANDFILL_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/landfill-performance/"
    }
  },
  {
    to: "/device-performance/",
    element: <DevicePerformance />,
    scope: "read:performance-dashboard",
    menu: false
  },
  {
    to: "/device-performance/:DEVICE_ID",
    element: <DevicePerformance />,
    scope: "read:performance-dashboard",
    menu: false,
    validation: {
      params: { "DEVICE_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/device-performance/"
    }
  },
  {
    label: "LANDFILL_MANAGEMENT",
    to: "/manage/landfills/",
    element: <ManageLandfills />,
    icon: faDumpster,
    scope: "update:landfill",
    menu: true
  },
  {
    to: "/manage/landfill/:LANDFILL_ID",
    element: <ManageLandfill />,
    scope: "update:landfill",
    resources: { landfillKey: "LANDFILL_ID" },
    menu: false,
    validation: {
      params: { "LANDFILL_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/manage/landfills/"
    }
  },
  {
    to: "/manage/device/:DEVICE_ID",
    element: <ManageDevice />,
    scope: "update:device",
    menu: false,
    validation: {
      params: { "DEVICE_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/manage/landfills/"
    }
  },
  {
    to: "/manage/device-group/:GROUP_ID",
    element: <ManageDeviceGroup />,
    scope: "update:device",
    menu: false,
    validation: {
      params: { "GROUP_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/manage/landfills/"
    }
  },
  {
    label: "COMPANY_MANAGEMENT",
    to: "/manage/companies/",
    element: <ManageCompanies />,
    icon: faBuilding,
    scope: "update:company",
    menu: true
  },
  {
    to: "/manage/company/:COMPANY_ID",
    element: <ManageCompany />,
    scope: "update:company",
    resources: { companyKey: "COMPANY_ID" },
    menu: false,
    validation: {
      params: { "COMPANY_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/manage/companies/"
    }
  },
  {
    label: "DEVICE_LOGS",
    to: "/device-logs/",
    element: <DeviceLogs />,
    icon: faClockRotateLeft,
    scope: "logs:device",
    menu: true
  },
  {
    to: "/device-logs/:DEVICE_ID",
    element: <DeviceLogs />,
    scope: "logs:device",
    menu: false,
    validation: {
      params: { "DEVICE_ID": p => !!p.match(/^[0-9a-fA-F]{24}$/)?.length },
      fallbackURL: "/device-logs/"
    }
  },
  {
    label: "ADMIN",
    to: "/admin/",
    element: <Admin />,
    icon: faToolbox,
    scope: "admin:all",
    menu: true
  },
];

const PageWrapper = ({ route }: { route: RouteType }) => {
  return <ProtectedRoute scope={route.scope} resources={route.resources} validation={route.validation}>
    <Suspense fallback={<LoadingOverlay visible />}>
      {route.element}
    </Suspense>
  </ProtectedRoute>
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(createRoutesFromElements(<>
  <Route path="/" element={<Layout />}  errorElement={<ErrorPage />}>
    { routes.map((route, i) => <Route key={`route-${i}`} path={route.to} errorElement={<ErrorPage />} element={<PageWrapper route={route} />} />) }
  </Route>
  <Route path="/login" element={<Login />} errorElement={<ErrorPage />} />
  <Route path="*" element={<Navigate to="/" />} errorElement={<ErrorPage />} />
</>))
