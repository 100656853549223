import axios from "./axios";
import { ControlAlgorithmLog, LandfillControlAlgorithmConfigs, ControlAlgorithmSettingsT } from "@apis/types";
import { saveAs } from "file-saver";
import { showNotification } from "@mantine/notifications";

export const getControlAlgorithmSettingsOfDevice = async (deviceID: string): Promise<ControlAlgorithmSettingsT> => {
  const res = await axios.get<ControlAlgorithmSettingsT>(`control-algorithm/device/${deviceID}`);
  return res.data;
}

export const getControlAlgorithmSettingsHistoryOfDevice = async (deviceID: string, date?: string): Promise<ControlAlgorithmSettingsT[]> => {
  const res = await axios.get<ControlAlgorithmSettingsT[]>(`control-algorithm/device/${deviceID}/settings-history`, { params: { date } });
  return res.data;
}

export const getControlAlgorithmSettingsOfLandfill = async (landfillID: string): Promise<{ settings: Record<string, ControlAlgorithmSettingsT>, ch4Stopped: string[] }> => {
  const res = await axios.get<{ settings: Record<string, ControlAlgorithmSettingsT>, ch4Stopped: string[] }>(`control-algorithm/landfill/${landfillID}`);
  return res.data;
}

export const updateControlAlgorithmSettings = async (deviceID: string, body: Partial<ControlAlgorithmSettingsT>): Promise<ControlAlgorithmSettingsT> => {
  const res = await axios.post<ControlAlgorithmSettingsT>(`control-algorithm/device/${deviceID}`, body);
  return res.data;
}

export const getControlAlgorithmConfigsOfLandfill = async (landfillID: string): Promise<LandfillControlAlgorithmConfigs> => {
  const res = await axios.get<LandfillControlAlgorithmConfigs>(`control-algorithm/landfill/${landfillID}/configs`);
  return res.data;
}

export const setAlgorithmConfigsOfLandfill = async (landfillID: string, body: LandfillControlAlgorithmConfigs): Promise<LandfillControlAlgorithmConfigs> => {
  const res = await axios.put<LandfillControlAlgorithmConfigs>(`control-algorithm/landfill/${landfillID}/configs`, body);
  return res.data;
}

export const exportControlAlgorithmSettings = async (landfillID: string): Promise<void> => {
  const res = await axios.get(`control-algorithm/landfill/${landfillID}/export`, { responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `controlalgorithmsettings-${landfillID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}
export const importControlAlgorithmSettings = async (landfillID: string, file: File): Promise<{ failed: string[] }> => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`control-algorithm/landfill/${landfillID}/import`, formData);
  return res.data;
}

export const getControlAlgorithmLogs = async (deviceID: string, date?: string): Promise<ControlAlgorithmLog[]> => {
  const res = await axios.get<ControlAlgorithmLog[]>(`control-algorithm/device/${deviceID}/logs`, { params: { date } });
  return res.data;
}
